import React, { Component } from 'react';

export interface LoadingProps {
  active: boolean;
}

export class LoadingAnimation extends Component<LoadingProps> {
  render() {
    const { active } = this.props;
    if (!active) {
      return <div />;
    }

    return (
      <div className="incoming_msg">
        <div className="incoming_msg_img">
          {' '}
          <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" />{' '}
        </div>
        <div className="received_msg">
          <div className="loading-bubble grey">
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
