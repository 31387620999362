import React, { Component } from 'react';
import { CookieService } from 'services/CookieService';

export interface TextBoxOptions {
  onTextSubmit: Function;
}

export interface TextBoxState {
  input: string;
}

export class ChatBotTextBox extends Component<TextBoxOptions, TextBoxState> {
  state = {
    input: '',
  };

  historyMessages: string[] = [];
  currentHistory = 0;

  constructor(props: TextBoxOptions) {
    super(props);

    this.sendRequest = this.sendRequest.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.loadHistory();
  }

  loadHistory() {
    const chatMessages = CookieService.getCookie('chat-messages');
    if (chatMessages) {
      this.historyMessages = chatMessages.split(',,');
    }
  }

  rememberMessage(text: string) {
    this.currentHistory = 0;
    if (this.historyMessages.length >= 5) {
      this.historyMessages.splice(0, 1);
    }

    this.historyMessages.push(text);

    CookieService.setCookie('chat-messages', this.historyMessages.join(',,'), 365);
  }

  sendRequest() {
    if (this.state.input) {
      this.props.onTextSubmit(this.state.input);
      this.setState({
        input: '',
      });
    }
  }

  onKeyDown({ key }: any) {
    if (key === 'Enter') {
      this.rememberMessage(this.state.input);
      this.sendRequest();
    } else if (key === 'ArrowUp') {
      if (this.currentHistory < this.historyMessages.length) {
        ++this.currentHistory;
      }

      const index = this.historyMessages.length - this.currentHistory;

      if (index >= 0 && index < this.historyMessages.length) {
        this.setState({
          input: this.historyMessages[index],
        });
      }
    } else if (key === 'ArrowDown') {
      if (this.currentHistory > 0) {
        --this.currentHistory;
      }
      const index = this.historyMessages.length - this.currentHistory;

      if (index >= 0 && index < this.historyMessages.length) {
        this.setState({
          input: this.historyMessages[index],
        });
      } else {
        this.setState({
          input: '',
        });
      }
    }
  }

  onInputChange({ target }: any) {
    this.setState({
      input: target.value,
    });
  }

  render() {
    return (
      <div className="type_msg">
        <div className="input_msg_write">
          <input
            type="text"
            className="write_msg"
            placeholder="Type a message"
            onChange={this.onInputChange}
            value={this.state.input || ''}
            onKeyDown={this.onKeyDown}
          />
          <button className="msg_send_btn" type="button" onClick={this.sendRequest}>
            <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    );
  }
}
