import React, { Component } from 'react';

export enum MessageType {
  None = -1,
  Incoming,
  Outgoing,
}

export interface ChatBotMessageOptions {
  type: MessageType;
  text: string;
  lines: string[];
  date: string;
}

export class ChatBotMessage extends Component<ChatBotMessageOptions> {
  constructor(props: ChatBotMessageOptions) {
    super(props);
  }

  render() {
    const { type, text, date, lines } = this.props;

    return (
      <div className={type === MessageType.Incoming ? 'incoming_msg' : ''}>
        {type === MessageType.Incoming && (
          <div className="incoming_msg_img">
            {' '}
            <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" />{' '}
          </div>
        )}
        <div className={type === MessageType.Incoming ? 'received_msg' : 'outgoing_msg'}>
          <div className={type === MessageType.Incoming ? 'received_withd_msg' : 'sent_msg'}>
            <p dangerouslySetInnerHTML={text ? { __html: text } : undefined}>
              {lines &&
                lines.map((l, i) => (
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: l }} />
                    {i < lines.length - 1 && (
                      <span>
                        <br />
                      </span>
                    )}
                  </div>
                ))}
            </p>
            <span className="time_date"> {date} </span>
          </div>
        </div>
      </div>
    );
  }
}
