import React, { Component } from 'react';
import { PanelProps } from '@grafana/data';
import { ChatBotOptions } from 'types';
import { ChatBotMessage, MessageType } from 'components/ChatBotMessage';
import { ChatBotTextBox } from 'components/ChatBotTextBox';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { getDataSourceSrv } from '@grafana/runtime';
import appEvents from 'grafana/app/core/app_events';

import axios from 'axios';

import './css/style.css';
import './css/spinner.scss';

export class ChatBotPanel extends Component<PanelProps<ChatBotOptions>> {
  state = {
    isLoading: false,
  };

  messages: any[] = [];
  proxyUrl = '';
  lastMessageCount = 0;
  containerRef: any = React.createRef();
  messagesDiv: any = React.createRef();

  constructor(props: any) {
    super(props);

    this.onInput = this.onInput.bind(this);
    this.messages.push({
      type: MessageType.Incoming,
      text: this.props.options.welcomeMessage,
      date: this.formatDate(new Date()),
    });

    this.messages.push({
      type: MessageType.Incoming,
      text: 'Try saying <b class="highlight">!help</b> to see available commands.',
      date: this.formatDate(new Date()),
    });

    this.getProxyURL();
    this.lastMessageCount = 2;
    // if (this.props.options.groupsVariable) {
    //   const api: any = getDataSourceSrv();

    //   const vars: any[] = api['templateSrv'].variables;
    //   const deparments = vars.find(v => v.name === this.props.options.groupsVariable);
    // }
  }

  getProxyURL() {
    const { icbApiName } = this.props.options;
    if (this.proxyUrl !== '') {
      return;
    }

    const srv = getDataSourceSrv();

    srv.get(icbApiName).then(
      response => {
        const us = response as any;
        this.proxyUrl = `/api/datasources/${us.id}/resources`;
      },
      err => {
        appEvents.emit('alert-warning', [err.message, '']);
      }
    );
  }

  formatDate(date: Date) {
    return `${date.getHours()}:${date.getMinutes()} | ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
  }

  onInput(text: string) {
    this.messages.push({
      type: MessageType.Outgoing,
      text: text,
      date: this.formatDate(new Date()),
    });

    this.setState({
      isLoading: true,
    });

    this.getProxyURL();
    const URL = window.location.origin + this.proxyUrl + '/publicapi/api/ChatBot/ProcessMessage';

    axios.post(URL, { message: text }).then(
      res => {
        if (res.data.commands) {
          this.messages.push({
            type: MessageType.Incoming,
            lines: res.data.commands.map((s: string) => s.replace(/<b>/g, '<b class="highlight">')),
            date: this.formatDate(new Date()),
          });
        } else {
          this.messages.push({
            type: MessageType.Incoming,
            text: res.data.response.replace(/<b>/g, '<b class="highlight">'),
            date: this.formatDate(new Date()),
          });
        }

        this.setState({
          isLoading: false,
        });
      },
      err => {
        appEvents.emit('alert-warning', ['Error', '']);

        this.messages.push({
          type: MessageType.Incoming,
          text: 'There was an error processing your request.',
          date: this.formatDate(new Date()),
        });

        this.setState({
          isLoading: false,
        });
        console.log(err);
      }
    );
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.messagesDiv.current && this.lastMessageCount !== this.messages.length) {
      this.lastMessageCount = this.messages.length;
      this.messagesDiv.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  render() {
    return (
      <div className="container mesgs">
        <div className="msg_history" ref={this.containerRef}>
          {this.messages.map(m => (
            <ChatBotMessage type={m.type} text={m.text} date={m.date} lines={m.lines} />
          ))}

          <LoadingAnimation active={this.state.isLoading} />
          <div ref={this.messagesDiv} />
        </div>
        <ChatBotTextBox onTextSubmit={this.onInput} />
      </div>
    );
  }
}
