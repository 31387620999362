import { PanelPlugin } from '@grafana/data';
import { ChatBotOptions } from './types';
import { ChatBotPanel } from './ChatBot';
 
export const plugin = new PanelPlugin<ChatBotOptions>(ChatBotPanel).setPanelOptions(builder => {
    return builder
        .addTextInput({
            path: 'icbApiName',
            name: 'API name'
        }) 
        .addTextInput({
            name: 'Welcome message',
            path: 'welcomeMessage'
        }) 
        .addTextInput({
            name: 'Cell width',
            path: 'cellWidth',
            defaultValue: '80px'    
        })
})
